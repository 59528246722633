@import "../../scss/index.scss";
@import "bootstrap/dist/css/bootstrap.min.css";

.App {
  font-family: sans-serif;
  text-align: center;
}

nav .navbar-collapse {
  position: fixed;
  top: 56px;
  background: #343a40;
  right: -100%;
  width: 100vw;
  height: 100vh;
  display: block;
  transition: right 0.3s ease;
}
.nav-link {
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  padding-top: 2%;
}
nav .navbar-collapse.show {
  right: 0;
  position: static;
}

.navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
  width: 20%;
}
