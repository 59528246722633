@import "../../../scss/index.scss";

.register-form{
    margin-top: 50px;

    h1{
       color: $font-light;
       text-align: center;
       font-size: 35px; 
       padding-bottom: 20px;
    }
 .ui.form {
     .field{
         .input{
             margin-top: 5px;

             input {
               background-color: $background-grey;
               border:0;
               font-size: 16px;
               color: $font-light;
               
              &:hover,
              &:active,
              &:focus {
                background-color: $background-grey-hover;
                border: 0;
              }

             }
             i{
                color: $font-light;
                font-size: 19px; 
             }
             &.error{
                 input {
                    border-bottom: 1px solid $danger; 
                 }
             }
         }
     }
     .button{
        width: 100%;
        background-color: $primary-color-orange;
        color: $font-light;
        text-transform: uppercase;
        margin-top: 10px;
        border-radius: 50px;
        padding: 17px 0;
        font-size: 14px;
        letter-spacing: 3px;
     }
  .error-text{
    color: $font-light;
    padding-left: 15px;
  }    
 } 
 &__options{
    margin-top: 50px;
    color: $font-light;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
   
    p{
       &:first-of-type{
           margin-bottom: 40px;
           &:hover {
             cursor: pointer;
           }
       } 

       &:last-of-type{
        span {  
         color: $font-grey;
         text-decoration: underline; 
         padding-left: 5px;
         &:hover{
           cursor: pointer;
         }
       }
    }
  } 
 }
}