@import "../../scss/index.scss";

.App {
  width: 100%;
  height: 100%;
  background-color: #101010;
  // text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    // animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background-color: #101010;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

form.trabajo-l1 {
  background: #141414;
  padding: 60px;
  width: 70%;
  max-width: calc(100% - 60px);
  padding: 1em;
}

select#350099162.form-control {
  margin-left: 20%;
}

.form-control-estilo {
  border: 0;
  border-bottom: 1px solid #fff;
  background-color: #141414;
  color: #fff;
  font-size: 70%;
  width: 80%;
  margin-left: 35%;
  margin-top: 0%;
  margin-bottom: 15%;
}

input#emailAddress.form-control {
  border: 0;
  border-bottom: 1px solid #fff;
  background-color: #141414;
  color: #fff;
  font-size: 70%;
  width: 80%;
  margin-left: 35%;
  margin-top: 0%;
  margin-bottom: 15%;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  justify-content: start;
  margin-left: -5%;
}

fieldset {
  border: 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

button.ui.button.btn-primary-uno {
  height: 40px;
  width: 50%;
  max-width: 200px;
  margin-top: 7%;
  background-color: #39c0c3;
  color: #fff;
  font-weight: bold;
  font-size: 60%;
  text-transform: uppercase;
  border-radius: 50px;
  // margin-left: 5%;
  border: none;
  // margin-right: auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  // border: 3px solid green;
}

h2.L1 {
  color: #fff;
  text-align: center;
  margin-left: 5%;
  margin-right: auto;
  font-size: 80%;
  // margin-left: -20px;
}

p.not-assigned {
  color: #fff;
  position: relative;
  top: 20px;
  text-align: center;
}

.parrafo-uno {
  color: #fff;
  text-align: center;
  margin-left: 5%;
  font-size: 60%;
  line-height: 160%;
  // margin-left: -20p
}

button,
select {
  width: 20%;
  margin-left: 5%;
  color: #fff;
  background-color: #141414;
  text-transform: none;
  font-size: 20px;
  border-radius: 5px;
}

legend.leyenda {
  color: #fff;
  text-align: start;
  padding-left: 0%;
  line-height: 250%;
  margin-left: 5%;
  font-size: 70%;
}

fieldset {
  border: 0;
  margin-left: auto;
  margin-right: auto;
}

input {
  background-color: #141414;
  color: #fff;
  margin-right: 4%;
  font-size: 70%;
}

select#1045128490.form-control {
  margin-left: 110px;
}

label.etiqueta {
  line-height: 200%;
  font-size: 70%;
  text-align: start;
  margin-left: 10%;
}

div.radio {
  line-height: 25px;
  justify-items: start;
  margin-right: auto;
  margin-left: auto;
  margin: 5px;
  // font-size: 70%;
  // left: 100px;
}

div.form-group {
  line-height: 25px;
  right: 200px;
  text-align: start;
  margin-right: 50%;
  margin: 5px;
  width: 70%;
}

option {
  font-size: 110%;
  border-radius: 10px;
  background-color: #101010;
}

.container {
  display: block;
  position: relative;
  padding-left: 75px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 110%;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

img.image {
  width: 40%;
  margin-right: auto;
  margin-left: 0%;
}

.Toastify__toast-body {
  text-align: center;
  margin-left: 25%;
}
