@import "../../../scss/index.scss";

.reset-form{
    margin-top: 50px;
  
    h1 {
      color: $font-light;
      text-align: center;
      font-size: 35px;
      padding-bottom: 20px;
    }
  
    .ui.form {
      .field {
        .input {
          margin-bottom: 5px;
  
          input {
            background-color: $background-grey;
            border: 0;
            font-size: 16px;
            color: $font-light;
  
            &:hover,
            &:active,
            &:focus {
              background-color: $background-grey-hover;
              border: 0;
            }
          }
  
          i {
            color: $font-light;
            font-size: 19px;
          }
          &.error {
            input {
              border-bottom: 1px solid $danger;
            }
          }
        }
      }
  
      .ui.button.change
      {
        text-transform: uppercase;
        width: 100%;
        margin-bottom: 30px;
        border-radius: 50px;
        padding: 20px 0;
        font-size: 17px;
        letter-spacing: 3px;
      }

        //////individual themes buttons
   .ui.button.change{
    background-color: $background-light;
    color: $font-dark;
}
.ui.button.login{
   
    background-color: $primary-color-orange;
    color: $font-light;
}
  
      .error-text {
        color: $font-light;
        padding-left: 15px;
      }
    }
  
    .resend-verification-email {
      color: $font-light;
      padding: 20px 20px 0 20px;
      text-align: center;
  
      span {
        color: $primary-color-orange;
        font-weight: bold;
        &:hover {
          cursor: pointer;
        }
      }
    }
  
    &__options {
      margin-top: 50px;
      color: $font-light;
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
  
      p {
        &:first-of-type {
          margin-bottom: 40px;
          &:hover {
            cursor: pointer;
          }
        }
  
        &:last-of-type {
          color: $font-grey;
  
          span {
            color: $font-light;
            text-decoration: underline;
            padding-left: 5px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

 }